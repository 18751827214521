import {addOrRemoveHiddenTeamIdInput, updateTeamIdsOnPaginationLinks} from './../orgs/invitations'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {onInput} from './../onfocus'

on('click', '.js-repo-toggle-team', async function ({currentTarget}) {
  //update the url of each pagination link (next/prev) to include the set of selected team_ids
  updateTeamIdsOnPaginationLinks('.js-repo-team-suggestions-pagination', '.js-repo-toggle-team')

  const checkBox = currentTarget as HTMLInputElement
  addOrRemoveHiddenTeamIdInput(checkBox, 'form.js-repository-teams')
})

onInput('.js-synced-repo-owner-input', function (event) {
  const input = event.target as HTMLInputElement
  const syncName = input.getAttribute('data-sync')
  if (!syncName) {
    return
  }

  const syncedElements = document.querySelectorAll(`[data-sync-with="${syncName}"]`)
  for (const syncedElement of syncedElements) {
    syncedElement.textContent = input.value
  }
})
