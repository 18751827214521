import {controller, target} from '@github/catalyst'
import {announceFromElement} from '../aria-live'

const maxInput = document.querySelector("input[name='min_runner_count']") as HTMLInputElement
const minInput = document.querySelector("input[name='min_runner_count']") as HTMLInputElement

@controller
class RunnerScalingElement extends HTMLElement {
  // Min runner input
  @target minInput: HTMLInputElement
  @target minGroup: HTMLElement
  @target minGreaterThanMaxErrorMessage: HTMLElement
  @target minNegativeErrorMessage: HTMLElement
  @target minTooHighErrorMessage: HTMLElement
  @target minNote: HTMLElement

  // Max runner input
  @target maxInput: HTMLInputElement
  @target maxGroup: HTMLElement
  @target maxGreaterThanMinErrorMessage: HTMLElement
  @target maxTooLowErrorMessage: HTMLElement
  @target maxTooHighErrorMessage: HTMLElement
  @target maxNote: HTMLElement

  disableSubmitButton() {
    const submitButton = document.querySelector<HTMLButtonElement>('.js-submit-custom-hosted-runner-button')!
    submitButton.disabled = true
  }

  enableSubmitButton() {
    const submitButton = document.querySelector<HTMLButtonElement>('.js-submit-custom-hosted-runner-button')!
    submitButton.disabled = false
  }

  minGreaterThanMax() {
    return +this.minInput.value > +this.maxInput.value
  }

  validateMin() {
    this.resetMinState()

    if (!this.minInput.value) return

    const minValue: number = +this.minInput.value

    const minInputUpperLimit = parseInt(minInput.max)
    const minInputLowerLimit = parseInt(minInput.min)

    // Remove error if changing min input fixes min greater than max error
    if (!this.minGreaterThanMax()) {
      this.maxGreaterThanMinErrorMessage.hidden = true
      if (this.maxTooLowErrorMessage.hidden === true && this.maxTooHighErrorMessage.hidden === true) {
        this.maxGroup.classList.remove('errored')
        this.enableSubmitButton()
      }
    }

    // Minimum is greater than max
    if (this.minGreaterThanMax()) {
      this.minGroup.classList.add('errored')
      this.minGreaterThanMaxErrorMessage.hidden = false
      announceFromElement(this.minNote)
      this.disableSubmitButton()
      return
    }

    // Minimum input is too low
    if (minValue < minInputLowerLimit) {
      this.minGroup.classList.add('errored')
      this.minNegativeErrorMessage.hidden = false
      announceFromElement(this.minNote)
      this.disableSubmitButton()
      return
    }

    // Minimum input is too high
    if (minValue > minInputUpperLimit) {
      this.minGroup.classList.add('errored')
      this.minTooHighErrorMessage.hidden = false
      announceFromElement(this.minNote)
      this.disableSubmitButton()
      return
    }
  }

  validateMax() {
    this.resetMaxState()

    if (!this.maxInput.value) return

    const maxValue: number = +this.maxInput.value

    const maxInputUpperLimit = parseInt(maxInput.max)
    const maxInputLowerLimit = parseInt(maxInput.min)

    // Remove error if changing max input fixes min greater than max error
    if (!this.minGreaterThanMax()) {
      this.minGreaterThanMaxErrorMessage.hidden = true
      if (this.minNegativeErrorMessage.hidden === true && this.minTooHighErrorMessage.hidden === true) {
        this.minGroup.classList.remove('errored')
        this.enableSubmitButton()
      }
    }

    // Maximum input is lower than minimum
    if (this.minGreaterThanMax()) {
      this.maxGroup.classList.add('errored')
      this.maxGreaterThanMinErrorMessage.hidden = false
      announceFromElement(this.maxNote)
      this.disableSubmitButton()
      return
    }

    // Maximum input is less than zero
    if (maxValue < maxInputLowerLimit) {
      this.maxGroup.classList.add('errored')
      this.maxTooLowErrorMessage.hidden = false
      announceFromElement(this.maxNote)
      this.disableSubmitButton()
      return
    }

    // Maximum input is too high
    if (maxValue > maxInputUpperLimit) {
      this.maxGroup.classList.add('errored')
      this.maxTooHighErrorMessage.hidden = false
      announceFromElement(this.maxNote)
      this.disableSubmitButton()
      return
    }
  }

  resetMinState() {
    this.minGreaterThanMaxErrorMessage.hidden = true
    this.minNegativeErrorMessage.hidden = true
    this.minTooHighErrorMessage.hidden = true
    this.minGroup.classList.remove('errored')
    this.enableSubmitButton()
  }

  resetMaxState() {
    this.maxGreaterThanMinErrorMessage.hidden = true
    this.maxTooHighErrorMessage.hidden = true
    this.maxTooLowErrorMessage.hidden = true
    this.maxGroup.classList.remove('errored')
    this.enableSubmitButton()
  }
}
