// eslint-disable-next-line no-restricted-imports
import {observe} from 'selector-observer'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {remoteForm} from '@github/remote-form'

// Disable email preference button if JS enabled.
observe('.js-email-global-unsubscribe-form', function (el) {
  el.querySelector<HTMLInputElement>('.js-email-global-unsubscribe-submit')!.disabled = true
})

// Disable button until email preference has changed from current value.
on('change', '.js-email-global-unsubscribe-form', function (event) {
  const target = event.currentTarget
  const unsubscribeButton = target.querySelector<HTMLInputElement>('.js-email-global-unsubscribe-submit')!
  const options = target.querySelectorAll<HTMLInputElement>('.js-email-global-unsubscribe')

  // Check if any of the options have changed
  const changed = Array.from(options).some(el => el.checked !== el.defaultChecked)

  // Disable the button if nothing has changed
  unsubscribeButton.disabled = !changed
})

remoteForm('.js-email-unsubscribe-form', async function (form, wants) {
  await wants.text()
  for (const el of document.querySelectorAll('.js-email-unsubscribe-message')) {
    if (el instanceof HTMLElement) el.hidden = !el.hidden
  }
})
