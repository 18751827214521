import {fetchSafeDocumentFragment} from '../fetch'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {pushState} from '../history'
import {ready} from '../document-ready'

// If the page parameter is provided, the page number in the
// container's src URL will be updated to the provided number.
// Otherwise the src URL will be used as-is.
//
// This function is exported so that it can be tested.
export async function retrieveAdvancedSecurityEntities(page?: number) {
  const container = document.querySelector<HTMLDivElement>('.js-ghas-entity-list-container')
  if (container == null) {
    return
  }

  const showOnError = container.querySelector<HTMLElement>('[data-show-on-error]')
  const loadingElement = container.querySelector<HTMLElement>('[data-loading]')

  const dataContainer = container.querySelector<HTMLElement>('[data-content-container]')
  if (dataContainer == null) {
    handleErrorState(showOnError, loadingElement, dataContainer)
    return
  }

  let src = container.getAttribute('data-src')
  if (src == null) {
    handleErrorState(showOnError, loadingElement, dataContainer)
    return
  }

  // Update the page number if one has been explicitly passed in
  // and hide the pager, replacing it with a spinner.
  //
  // There's no javascript to handle showing the pager again and
  // hiding the paging spinner, since the container's entire contents
  // - including the pager - are replaced with the response data (or
  // error message) anyway!
  if (page !== undefined) {
    const srcURL = new URL(src, window.origin)

    srcURL.pathname = srcURL.pathname.replace(/\d+$/, page.toString())

    const newSrc = srcURL.toString()

    if (src === newSrc) {
      // another container has triggered a statechange but we are still on the correct page and do not need
      // to fetch again
      return
    }

    src = newSrc

    const pager = container.querySelector<HTMLElement>('.js-advanced-security-entities-pagination')
    if (pager) {
      pager.hidden = true
    }
    const pagerLoadingElement = container.querySelector('[data-pager-loading]')
    if (pagerLoadingElement) {
      pagerLoadingElement.removeAttribute('hidden')
    }
  } else {
    if (dataContainer.children.length > 0) {
      // another pjax container has reloaded but we are already showing a page and we do not need
      // to fetch again
      return
    }
  }

  let entityList
  try {
    entityList = await fetchSafeDocumentFragment(document, src)
  } catch {
    handleErrorState(showOnError, loadingElement, dataContainer)
    return
  }
  if (!entityList) {
    handleErrorState(showOnError, loadingElement, dataContainer)
    return
  }

  if (loadingElement) {
    loadingElement.hidden = true
  }

  dataContainer.innerHTML = ''
  dataContainer.appendChild(entityList)
}

function handleErrorState(
  showOnError: HTMLElement | null,
  loadingElement: HTMLElement | null,
  dataContainer: HTMLElement | null
) {
  if (showOnError) {
    showOnError.hidden = false
  }
  if (loadingElement) {
    loadingElement.hidden = true
  }
  if (dataContainer) {
    dataContainer.innerHTML = ''
  }
}

// retrieve the repo/org list on page load
;(async () => {
  await ready
  retrieveAdvancedSecurityEntities()
})()

// switching between settings pages is handled by pjax, so doesn't trigger a
// full page reload. Listen for pjax:end to handle this
document.addEventListener('pjax:end', function () {
  retrieveAdvancedSecurityEntities()
})

window.addEventListener('popstate', function (event) {
  if (event.state === null) {
    // the user has popped through all the states and gone back to the first page
    // this will not trigger a statechange event so we must reload it explicitly
    retrieveAdvancedSecurityEntities(1)
  }
})

// reload entries on a push/pop state change
window.addEventListener('statechange', function () {
  const pageNumber = parseInt(
    // See AdvancedSecurityEntitiesLinkRenderer::PAGE_PARAM for param name
    new URLSearchParams(document.location.search).get('advanced_security_entities_page') || ''
  )
  if (isNaN(pageNumber)) {
    return
  }

  retrieveAdvancedSecurityEntities(pageNumber)
})

on('click', '.js-advanced-security-entities-pagination a', function (event) {
  if (event == null || event.target == null) {
    return
  }
  const href = (event.target as HTMLAnchorElement).href

  const hrefURL = new URL(href, window.origin)

  const currentParams = new URLSearchParams(document.location.search)

  for (const [key, value] of currentParams) {
    if (!hrefURL.searchParams.has(key)) {
      hrefURL.searchParams.append(key, value)
    }
  }

  pushState(null, '', hrefURL.toString())

  event.preventDefault()
})
