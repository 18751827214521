import DetailsDialogElement from '@github/details-dialog-element'
import {SelectedItemCountElement} from './selected-item-count'
import {SelectedItemListElement} from './selected-item-list'
import {hasDirtyFields} from '../has-interactions'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

const selected = 'SELECTED'

on(
  'details-menu-selected',
  '.js-runner-group-component-visibility-menu',
  function (event) {
    const target = event.detail.relatedTarget
    const runnerGroupForm = target.closest('.js-runner-group-form') as HTMLElement
    const value = target.getAttribute('value')!
    runnerGroupForm.querySelector<HTMLElement>('.js-runner-group-targets-selection')!.hidden = value !== selected

    // hide the count of items if visibility is not set to "Selected" (i.e. Visbility is set to "All")
    runnerGroupForm.querySelector<HTMLElement>('.js-runner-group-targets-count')!.hidden = value !== selected
  },
  {capture: true}
)

on(
  'details-menu-selected',
  '.js-runner-group-component-workflow-menu',
  function (event) {
    const target = event.detail.relatedTarget
    const runnerGroupForm = target.closest('.js-runner-group-form') as HTMLElement
    const value = target.getAttribute('value')!
    runnerGroupForm.querySelector<HTMLElement>('.js-runner-group-workflow-selection')!.hidden = value !== selected

    // hide the count of items if visibility is not set to "Selected" (i.e. Visbility is set to "All")
    runnerGroupForm.querySelector<HTMLElement>('.js-runner-group-workflow-count')!.hidden = value !== selected
  },
  {capture: true}
)

on('details-dialog-close', '.dialog-visibility', function (event) {
  const selectedItems = (event.target as Element).closest<SelectedItemListElement>('selected-item-list')!
  if (!selectedItems) {
    return
  }
  for (const item of selectedItems.items) {
    // reset checks to last saved state
    item.checked = item.defaultChecked
  }
  selectedItems.updateCount()
})

on('click', '.js-btn-select-items.visibility-targets', function (event) {
  const detailsDialog = (event.target as Element).closest<DetailsDialogElement>('details-dialog')!
  // save the changes to selected items
  const selectedItems = (event.target as Element).closest<SelectedItemListElement>('selected-item-list')!
  if (hasDirtyFields(detailsDialog)) {
    for (const item of selectedItems.items) {
      item.defaultChecked = item.checked
    }
  }

  // Close the details dialog
  detailsDialog.toggle(false)

  // update the selected items count
  const selectedItemCount = selectedItems.querySelector<SelectedItemCountElement>('selected-item-count')!
  selectedItemCount.updateCount(Number(selectedItems.selectedCount.textContent))
})

// user confirms the list of workflows should be applied
on('click', '.js-btn-select-items.workflow-refs', function (event) {
  const detailsDialog = (event.target as Element).closest<DetailsDialogElement>('details-dialog')!
  // save the changes to selected items
  const selectedItems = (event.target as Element).closest<SelectedItemListElement>('selected-item-list')!
  const textarea = detailsDialog.querySelector<HTMLTextAreaElement>('.js-runner-group-workflow-selection textarea')!

  // save the changes to selected items
  textarea.defaultValue = textarea.value

  // Close the details dialog
  detailsDialog.toggle(false)

  // Heuristic: count the number of unique workflow files in the textarea by counting the words that have no commas or whitespace characters
  const workflowRefsCount = new Set(textarea.value.match(/[^,\s]+/g)).size
  const selectedItemCount = selectedItems.querySelector<SelectedItemCountElement>('selected-item-count')!
  selectedItemCount.updateCount(workflowRefsCount)
})

// Make sure the save button for the workflow dialog is *disabled* unless there's a change to the textarea.
// Like "data-required-change" from html-validation.ts, but *doesn't force us to validate the entire form*.
// When creating a new group, we want the user to be able to click "Update" without having to validate the entire form
// (since we just need to validate the workflow refs textarea in that case)
function installTextAreaChangeListeners(textarea: HTMLTextAreaElement) {
  function customValidity() {
    const unchanged = textarea.value === textarea.defaultValue
    const submitButton = textarea.closest('form')!.querySelector('.js-btn-select-items.workflow-refs')
    unchanged ? submitButton?.setAttribute('disabled', 'disabled') : submitButton?.removeAttribute('disabled')
    textarea.setCustomValidity(unchanged ? 'unchanged' : '')
  }
  if (textarea.hasAttribute('data-uninitialized')) {
    textarea.addEventListener('change', customValidity)
    textarea.addEventListener('input', customValidity)
    textarea.removeAttribute('data-uninitialized')
  }
}

// when the "workflow access" dialog opens or closes
on(
  'toggle',
  '.details-dialog-workflow',
  function (event) {
    const details = event.currentTarget as HTMLDetailsElement
    const textarea = details.querySelector<HTMLTextAreaElement>('.js-runner-group-workflow-selection textarea')!

    if (details.open) {
      // when the modal is open, we require a change to the textarea in order to be submittable (and activate the save button)
      installTextAreaChangeListeners(textarea)
    } else {
      // when the modal is closed, just discard whatever its value is and reset to its defaultValue.
      // NB: textarea.defaultValue is updated when the user clicks the submit button.
      textarea.value = textarea.defaultValue
    }
  },
  {capture: true}
)
